import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useSiedeBar, useAuth, useTheme, useSocket, useToast } from 'contexts';

import { dynamicSort } from 'utils/sort';

import { IUsersOnline } from 'contexts/AuthProvider/types';

import brandImg from '../../assets/brand.png';
import avartarImgDefault from '../../assets/avatar.png';

import * as S from './styles';

const Header: React.FC = () => {
  const {
    signOut,
    user,
    updateUsersOnline,
    returnUser,
    tokenAdmin,
  } = useAuth();
  const { onToogleOpenSideBar } = useSiedeBar();
  const { theme, onChangeTheme } = useTheme();
  const { connectSocket, disconnectSocket, socket } = useSocket();
  const { addToast } = useToast();
  const history = useHistory();

  const avatarImg = user.avatar_url ? user.avatar_url : avartarImgDefault;

  useEffect(() => {
    connectSocket();
  }, [connectSocket]);

  useEffect(() => {
    return () => {
      disconnectSocket();
    };
  }, [disconnectSocket]);

  const listenSocket = useCallback((): void => {
    socket?.on('USERS_ONLINE', (users_online: IUsersOnline) => {
      // Object.keys = cria um array com a chave (user_id)
      // Object.values = Cria array com os valores (tenant_id, user_id, user_name ....)

      updateUsersOnline(
        Object.values(users_online)
          .map((item) => {
            return { ...item };
          })
          .sort(dynamicSort('user_name')), // -user_name > para ordem decrescente
      );
    });

    socket?.on('SIGNOUT', () => {
      signOut();

      addToast({
        type: 'error',
        title: 'Novo Login',
        description: 'Essa conta acabou de fazer login em outro dispositivo',
      });
    });
  }, [addToast, signOut, socket, updateUsersOnline]);

  const handleReturnUser = useCallback(
    async (token: string): Promise<void> => {
      try {
        returnUser(token);
        history.push(`/`);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo!',
        });
      }
    },
    [addToast, history, returnUser],
  );

  useEffect(() => {
    if (socket) {
      listenSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenSocket]);

  const handleToogleMenu = useCallback(() => {
    onToogleOpenSideBar();
  }, [onToogleOpenSideBar]);

  const handleSetTheme = useCallback(
    (themeSelected: string) => {
      onChangeTheme(themeSelected);
    },
    [onChangeTheme],
  );

  const navegateToProfile = useCallback(() => {
    history.push('/profile');
    // eslint-disable-next-line
  }, []);

  return (
    <S.Container>
      <S.WrapperLeft>
        <S.IconMenu onClick={handleToogleMenu} size={24} />
        <S.Brand width="24" height="24" src={brandImg} />
        <S.Title>A1 Analytics</S.Title>
      </S.WrapperLeft>

      <S.WrapperRight>
        {tokenAdmin !== '' && (
          <S.AvatarInfo>
            <S.Avatar
              src={avatarImg}
              role="button"
              onClick={() => handleReturnUser(tokenAdmin)}
            />
            <S.UserInfo>
              <S.UserName>Retornar Admin</S.UserName>
              {/* <RoleName>{user.users_roles[0]}</RoleName> */}
            </S.UserInfo>
          </S.AvatarInfo>
        )}

        {theme === 'dark' && (
          <S.IconDarkTheme size={24} onClick={() => handleSetTheme('light')} />
        )}
        {theme === 'light' && (
          <S.IconLightTheme size={24} onClick={() => handleSetTheme('dark')} />
        )}
        <S.IconNotifications size={24} />

        {/* <IconContainer>
          <MdSettings size={24} />
        </IconContainer> */}
        <S.AvatarInfo>
          <S.Avatar src={avatarImg} role="button" onClick={navegateToProfile} />
          <S.UserInfo>
            <S.UserName>{user.name}</S.UserName>
            {/* <RoleName>{user.users_roles[0]}</RoleName> */}
          </S.UserInfo>
        </S.AvatarInfo>
        <S.IconSettings onClick={signOut} size={24} />
      </S.WrapperRight>
    </S.Container>
  );
};

export default Header;
