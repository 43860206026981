import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { MdSearch } from 'react-icons/md';
import { FaTags } from 'react-icons/fa';

import {
  InputSearch,
  ButtonLink,
  HeaderBackground,
  HeaderPage,
  HeaderInfo,
  Table,
  Pagination,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Position,
  Button,
  Loader,
  TableResponsive,
  PopUpMenu,
  Can,
} from 'components';

import { ModalHandles } from 'components/Modal';

import api from 'services/api';

import { useAuth, useToast } from 'contexts';

import * as S from './styles';

interface User {
  id: string;
  name: string;
  email: string;
  password: string;
  permission: { name: string };
  rls: string;
}

interface Paginate {
  data: User[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const UserList: React.FC = () => {
  const [users, setUsers] = useState<Paginate>();
  const [selectedUser, setSelectedUser] = useState<User>();
  const modalRemoverRef = useRef<ModalHandles>(null);

  const { addToast } = useToast();
  const { tokenAdmin, changeUser } = useAuth();

  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const history = useHistory();

  const handleSearch = useCallback(
    async ({ page, per_page }) => {
      try {
        setLoading(true);

        const params = {
          page,
          per_page,
          term,
        };

        const response = await api.get<Paginate>('/users', { params });

        const { data } = response;

        setUsers(data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo de pesquisa',
        });
      } finally {
        setLoading(false);
      }
    },
    [term, addToast],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 10,
    };

    handleSearch(params);
  }, [handleSearch]);

  const handleRemove = async (): Promise<void> => {
    try {
      await api.delete(`/users/${selectedUser?.id}`);

      if (!users) return;
      const newList = users.data.filter((item) => item.id !== selectedUser?.id);

      setUsers({ ...users, data: newList });

      modalRemoverRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Usuario removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao inserir um novo registro',
      });
    }
  };

  const handleChangeUser = async (user_id: string): Promise<void> => {
    try {
      changeUser(user_id);
      history.push(`/`);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops...',
        description: 'Ops, ocorreu um erro no processo!',
      });
    }
  };

  const handleOpenModalRemove = useCallback((userSelected: User): void => {
    setSelectedUser(userSelected);
    modalRemoverRef.current?.openModal();
  }, []);

  return (
    <S.Container>
      <HeaderBackground>
        <HeaderPage>
          <HeaderInfo title="Usuários" icon={FaTags} />

          <S.ContainerSearch>
            <InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) =>
                e.key === 'Enter'
                  ? handleSearch({ page: 1, per_page: users?.per_page })
                  : {}
              }
            />
          </S.ContainerSearch>

          <div>
            <Can checkAccess="users_create">
              <ButtonLink title="Adicionar usuário" to="/users/add">
                Adicionar
              </ButtonLink>
            </Can>
          </div>
        </HeaderPage>
      </HeaderBackground>

      <S.Panel>
        {loading ? (
          <Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <TableResponsive>
              <Table>
                <thead>
                  <tr>
                    <th className="text-left">Usuário</th>
                    <th className="text-left">Email</th>
                    <th className="text-left">Permissão</th>
                    <th className="text-left">RLS</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.data.map((item: User) => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.permission.name}</td>
                        <td>{item.rls}</td>
                        <td className="text-center">
                          <PopUpMenu>
                            <Can checkAccess="users_update">
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(`/users/edit/${item.id}`)
                                }
                              >
                                <S.IconEdit
                                  title="Editar"
                                  size={24}
                                  color="#707070"
                                />
                                Editar
                              </button>
                            </Can>

                            <Can checkAccess="users_delete">
                              <button
                                type="button"
                                onClick={() => handleOpenModalRemove(item)}
                              >
                                <S.IconDelete
                                  title="Remover"
                                  size={24}
                                  color="#707070"
                                />
                                Remover
                              </button>
                            </Can>

                            {tokenAdmin === '' ? (
                              <Can checkAccess="users_update">
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleChangeUser(item.id);
                                  }}
                                >
                                  <S.IconView
                                    title="Editar"
                                    size={24}
                                    color="#707070"
                                  />
                                  View
                                </button>
                              </Can>
                            ) : (
                              <></>
                            )}
                          </PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </TableResponsive>
            <S.FooterContainer>
              {users && (
                <Pagination
                  page={users?.page}
                  total={users?.total}
                  per_page={users?.per_page}
                  last_page={users?.last_page}
                  onSearch={handleSearch}
                />
              )}
            </S.FooterContainer>
          </>
        )}
      </S.Panel>

      <Modal ref={modalRemoverRef} options={{ width: '400px' }}>
        <ModalHeader>
          <ModalTitle>Você Confirma a EXCLUSÃO?</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>O registro será removido completamente!</p>
        </ModalBody>
        <ModalFooter>
          <Position align="right">
            <Button
              variant="outline-secondary"
              onClick={() => modalRemoverRef.current?.closeModal()}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleRemove()}
              style={{ marginLeft: '16px' }}
            >
              SIM, confirmo a exclusão
            </Button>
          </Position>
        </ModalFooter>
      </Modal>
    </S.Container>
  );
};

export default UserList;
