import styled from 'styled-components';

import { PowerBIEmbed } from 'powerbi-client-react';

export const Container = styled.div`
  flex: 1;
  margin-top: -48px;
  position: relative;
`;

export const Embedded = styled(PowerBIEmbed)``;

export const IconContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin-top: 53px;
  margin-left: 5px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;
