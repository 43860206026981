import React from 'react';

import { AuthProvider } from '../AuthProvider';
import { ToastProvider } from '../ToastProvider';
import { ThemeProvider } from '../ThemeProvider';
import { SocketProvider } from '../SocketProvider';

export const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ThemeProvider>
      <SocketProvider>
        <ToastProvider>{children}</ToastProvider>
      </SocketProvider>
    </ThemeProvider>
  </AuthProvider>
);
